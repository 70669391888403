<template>
    <div class="main">
        <div class="title">{{ title }}</div>
        <div class="top">
            <el-form ref="form" :model="formData" :rules="rules">
                <el-form-item
                    :label="`${caseType === 'DECLASSIFICATION' ? '需求脱密专利名称' : '需求专利名称'}`"
                    prop="name"
                >
                    <el-input style="width: 400px" placeholder="例：一种***方法 / 一种***装置" v-model="formData.name"></el-input>
                </el-form-item>
                <el-form-item
                    prop="keyword"
                    :label="`${caseType === 'DECLASSIFICATION' ? '需求脱密专利包含关键字' : '需求专利包含关键字'}`"
                >
                    <el-input
                        style="width: 400px"
                        placeholder="请输入需求脱密专利包含关键字"
                        v-model="formData.keyword"
                    ></el-input>
                </el-form-item>
                <el-form-item v-if="caseType === 'GENERAL'" prop="patentTypeId" label="专利类型">
                    <el-select
                        v-model="formData.patentTypeId"
                        clearable
                        filterable
                        placeholder="请选择"
                        style="width: 200px"
                    >
                        <el-option
                            v-for="item in patentTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="patentStatusId" v-if="caseType === 'GENERAL'" label="法律状态">
                    <el-select
                        v-model="formData.patentStatusId"
                        clearable
                        filterable
                        placeholder="请选择"
                        style="width: 200px"
                    >
                        <el-option
                            v-for="item in patentStatusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="industryClass" v-if="caseType === 'GENERAL'" label="行业分类">
                    <el-select style="width: 200px" v-model="formData.industryClass" placeholder="请选择">
                        <el-option
                            v-for="item in industryOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="industryClass" v-else label="行业分类">
                    <el-select style="width: 200px" v-model="formData.industryClass" placeholder="请选择">
                        <el-option
                            v-for="item in industryOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="description" label="需求描述" class="block">
                    <el-input
                        style="width: 400px"
                        type="textarea"
                        v-model="formData.description"
                        maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 6, maxRow: 6 }"
                        placeholder="请填写需求描述，500字以内"
                        resize="none"
                    ></el-input>
                </el-form-item>
                <el-form-item label="交易方式" prop="modeId">
                    <el-radio-group v-model="formData.modeId">
                        <el-radio v-for="item in tradingMethodOption" :key="item.value" :label="item.value">
                            {{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="expectedPrice" label="预算(万元)">
                    <el-input-number
                        v-model="formData.expectedPrice"
                        :disabled="formData.negotiateDirectly"
                        @change="Price"
                        :precision="1"
                        :controls="false"
                        placeholder="请输入"
                        style="margin-right: 10px; width: 200px"
                    >
                    </el-input-number>

                    <el-checkbox @change="Directly" v-model="formData.negotiateDirectly">面议</el-checkbox>
                </el-form-item>
                <el-form-item prop="contact" label="联系人">
                    <el-input style="width: 200px" v-model="formData.contact" placeholder="请输入联系人"></el-input>
                </el-form-item>

                <el-form-item prop="phone" label="联系电话">
                    <el-input style="width: 200px" v-model="formData.phone" placeholder="请输入联系电话"></el-input>
                </el-form-item>

                <el-form-item prop="address" label="所在地区">
                    <el-input style="width: 400px" v-model="formData.address" placeholder="请输入所在地址"></el-input>
                </el-form-item>

                <el-form-item prop="email" label="邮箱">
                    <el-input style="width: 200px" v-model="formData.email" placeholder="请输入邮箱"></el-input>
                </el-form-item>
            </el-form>
            <div class="btn">
                <el-button type="primary" @click="onSave">确定</el-button>
                <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
                <el-button type="info" @click="$router.go(-1)">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { tradingMethodOption, patentStatusOptions, patentTypeOptions, phonePattern } from '../../../utils/variables';
import { mapState } from 'vuex';
export default {
    created() {
        console.log(this.caseType);
        if (this.$route.query.id) {
            this.$http
                .get('patentDemand/get/' + this.$route.query.id)
                .then(res => {
                    // console.log(res);
                    this.formData = res;
                    this.formData = { ...res, email: this.userInfo.email };
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
        this.$http
            .post('/setting/byFlag', { flag: 13 })
            .then(res => {
                if (res.length > 0) {
                    this.industryOptions = res.map(item => {
                        return {
                            label: item.name,
                            value: item.id
                        };
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
        this.$http
            .post('/setting/byFlag', { flag: 1 })
            .then(res => {
                if (res.length > 0) {
                    this.industryOption = res.map(item => {
                        return {
                            label: item.name,
                            value: item.id
                        };
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
        this.formData = {
            contact: this.userInfo.nickname,
            phone: this.userInfo.phone,
            email: this.userInfo.email
        };
    },
    data() {
        return {
            saving: false,
            formData: {},
            needChange: true,
            industryOptions: [],
            industryOption: [],
            tradingMethodOption,
            patentStatusOptions,
            patentTypeOptions
        };
    },
    computed: {
        ...mapState(['userInfo']),
        caseType() {
            return this.$route.query.caseType;
        },
        title() {
            const list = ['发布需求信息-脱密专利', '发布需求信息-专利'];
            return list[this.caseType == 'DECLASSIFICATION' ? 0 : 1];
        },
        rules() {
            return {
                name: [
                    {
                        required: true,
                        message: `请输入需求${this.caseType === 'DECLASSIFICATION' ? '脱密专利' : '专利'}名称`,
                        trigger: 'blur'
                    }
                ],
                // keyword: [
                //     {
                //         required: true,
                //         message: `请输入需求${this.caseType === 'DECLASSIFICATION' ? '脱密专利' : '专利'}包含关键字`,
                //         trigger: 'blur'
                //     }
                // ],
                // patentTypeId: [{ required: true, message: '请选择专利类型', trigger: 'blur' }],
                // patentStatusId: [{ required: true, message: '请选择法律状态', trigger: 'blur' }],
                // industryClass: [{ required: true, message: '请选择所属领域', trigger: 'blur' }],
                // description: [{ required: true, message: '请填写需求描述', trigger: 'blur' }],
                // modeId: [{ required: true, message: '请选择交易方式', trigger: 'blur' }],
                // expectedPrice: [
                //     {
                //         required: true,
                //         validator: (rule, value, callback) => {
                //             if (!this.formData.negotiateDirectly) {
                //                 if (!this.formData.expectedPrice) {
                //                     callback(new Error('请选择预算方式'));
                //                 } else {
                //                     callback();
                //                 }
                //             } else {
                //                 callback();
                //             }
                //         }
                //     }
                // ],
                // contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                // phone: [{ required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' }],
                // email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                // address: [{ required: true, message: '请输入所在地区', trigger: 'blur' }]
            };
        }
    },
    methods: {
        Price() {
            if (this.formData.expectedPrice) {
                this.formData.negotiateDirectly = false;
            }
        },
        Directly() {
            if (this.formData.negotiateDirectly == true) {
                this.$set(this.formData, 'expectedPrice', undefined);
            }
            this.$forceUpdate();
        },
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                    setTimeout(() => {
                        this.$confirm('发布成功，正在等待平台审核', '提示', {
                            confirmButtonText: '继续发布',
                            cancelButtonText: '退出',
                            type: 'warning'
                        }).then(() => {
                            setTimeout(() => {
                                this.$router.push('/patentDemandEdit?caseType=' + this.caseType);
                                this.formData = '';
                                this.formData = {
                                    contact: this.userInfo.nickname,
                                    negotiateDirectly: false,
                                    phone: this.userInfo.phone,
                                    email: this.userInfo.email
                                };
                            }, 1000);
                        });
                        // .catch(() => {
                        //     this.show = false;
                        // });
                    }, 1000);
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = {
                ...this.formData,
                caseType: this.caseType,
                status: 'PENDING',
                userId: this.userInfo.id,
                contact: this.userInfo.nickname,
                phone: this.userInfo.phone,
                email: this.userInfo.email
            };
            this.saving = true;
            console.log(data);
            this.$http
                .post('/patentDemand/save', data, { body: 'json' })
                .then(() => {
                    this.saving = false;
                    this.$message.success('发布成功');
                    this.show = false;
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/patentDemand/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label {
    width: 190px;
    white-space: nowrap;
    color: #313233;
    text-overflow: ellipsis;
    line-height: 40px;
}
/deep/ .el-form-item__error {
    margin-left: 189px;
}
/deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
}
/deep/ .el-checkbox__inner {
    border-radius: 50%;
}
/deep/ .el-button {
    width: 100px;
    border-radius: 4px;
    margin-left: 25px;
}
.main {
    background-color: #fff;
    margin: 17px 20px 0;
    padding-bottom: 60px;
    .btn {
        text-align: right;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        padding-bottom: 17px;
        line-height: 26px;
        border-bottom: 1px solid @bg;
    }
    .top {
        margin-top: 30px;
    }
}
</style>
